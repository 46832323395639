<template>
  <div
    class="flex justify-between mb-2 pt-2 pb-10 line-category-allergie border-0 border-b-2 border-solid border-gray-400"
  >
    <div class="flex justify-between">
      <label class="mr-5">{{ category.name }}: </label>
      <!-- toggle-button v-model="categoryPatient.hasIt" :sync="true" :labels="{checked: 'On', unchecked: 'Off'}"/ -->
    </div>
    <div class="flex flex-col w-1/2">
      <div
        class="flex flex-col lg:flex-row justify-end mr-1"
        v-if="categoryPatient.hasIt"
      >
        <div class="mr-2 w-1/2">
          <!-- v-select :options="category.list" :reduce="category => category._id" v-model="categoryPatient.allergies" label="name" multiple /-->

          <div class="relative border-none mr-2 w-full">
            <multiselect
              v-model="selectedAllergy"
              :options="dataAllergies"
              :multiple="false"
              placeholder="Cherchez une allergie"
              :searchable="true"
              track-by="name"
              label="name"
            >
              <span slot="noResult">Oops! Aucun element n'est trouvé.</span>
            </multiselect>
          </div>
        </div>
        <div class="relative border-none flex flex-row w-1/2">
          <input
            type="text"
            v-model="moreInfoAllergy"
            class="bg-white text-dokBlue-ultra appearance-none border border-solid border-dokBlue-ultra inline-block py-2 pl-3 pr-8 rounded leading-none placeholder-gray-500 w-full"
            placeholder="Plus d'info"
          />
          <div class="ml-1" style="width:35px;">
            <button
              v-if="selectedAllergy"
              @click="assingAllergies"
              :class="
                'bg-transparent border-0 mt-1 py-2 px-2 rounded-cu text-blue-500 border border-blue-400 cursor-pointer'
              "
            >
              <i :class="'fas fa-' + saveBtn.icon"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="w-full flex justify-end" v-if="patientAllergies.list.length">
        <table class="w-full table-auto">
          <thead class="hidden">
            <tr>
              <th class="px-4 py-2 bg-dokBlue-ultra text-white">Allergie</th>
              <th class="px-4 py-2 bg-dokBlue-ultra text-white">Plus d'info</th>
              <th style="width: 33px;"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(catAlr, index) in patientAllergies.list"
              :key="index"
              class="tr-alergy"
            >
              <td
                class="border-0 border-b border-solid border-gray-300 px-4 py-2 w-1/2"
              >
                <b>{{
                  catAlr.allergy ? catAlr.allergy.name : catAlr.allergyName
                }}</b>
                {{
                  catAlr.allergy && catAlr.allergy.parent
                    ? "( " + catAlr.allergy.parentName + " )"
                    : ""
                }}
              </td>
              <td
                class="border-0 border-b border-solid border-gray-300 pl-4 py-2 w-5/12 relative"
              >
                {{ catAlr.moreInfo }}
                <button
                  @click="removeAllergyToPatient(catAlr)"
                  class="rmv-alrg-btn bg-transparent text-red-600 border-0 border-red-500 rounded-cu text-white cursor-pointer"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </td>
              <td class="w-1/12"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
const toggleInput = () => import("@/views/global-components/toggle");
import vSelect from "vue-select";

export default {
  props: ["category", "patient", "patientAllergies"],
  components: { toggleInput, vSelect },
  data() {
    return {
      selectedAllergy: null,
      moreInfoAllergy: "",
      dataAllergies: [],
      categoryPatient: { hasIt: true },
      saveBtn: {
        color: "nblue",
        icon: "plus"
      }
    };
  },
  mounted() {
    var el = this;
    this.$store
      .dispatch("healthbook/GET_ALLERGIE_LIST", {
        category: this.category.slug
      })
      .then(function(response) {
        el.dataAllergies = response.data;
      });
    if (!this.category.patient) {
      this.categoryPatient = {
        hasIt: true,
        category: this.category.name,
        moreInfo: "",
        allergies: []
      };
    } else {
      this.categoryPatient = this.category.patient;
    }
  },
  /*
    watch: {
      'categoryPatient.hasIt': function(newVal) {
        const el = this
        if(!newVal && this.categoryPatient._id) {
          var vonf = confirm("Voulez-vous vraiment desactiver cette catégorie? \nSi vouz acceptez, toutes les informations seront perdues.")
          if(vonf) {
            this.$store
            .dispatch("healthbook/REMOVE_PATIENT_ALLERGIE", {
              patientId: this.patient._id,
              data:this.categoryPatient,
              onData:function() {
                el.category.patient = null;
                el.categoryPatient = {
                  hasIt : false,
                  category: el.category.name,
                  moreInfo: '',
                  allergies: []
                }
                el.$forceUpdate();
              }
            });
          } else {
            setTimeout(function(){
              el.categoryPatient.hasIt = true
            }, 200);
          }

        }
      },
    },
  */
  methods: {
    removeAllergyToPatient(catAlr) {
      // //console.log(catAlr);
      const el = this;
      this.$store.dispatch("healthbook/REMOVE_PATIENT_ALLERGIE", {
        patientId: this.patient._id,
        allergy: catAlr._id,
        onData: function() {
          el.$emit("removeCategory", catAlr._id, el.category.slug);
          el.$forceUpdate();
        }
      });
    },
    assingAllergies() {
      this.saveBtn = {
        color: "yellow-500",
        icon: "sync"
      };
      this.$store.dispatch("healthbook/SAVE_PATIENT_ALLERGIE", {
        patientId: this.patient._id,
        data: {
          // category:this.category.name,
          category: this.category.slug,
          allergyName: this.selectedAllergy.name,
          allergy: this.selectedAllergy._id,
          moreInfo: this.moreInfoAllergy
        },
        // data:this.categoryPatient,
        onData: this.onData
      });
    },
    onData(data) {
      if (data.ok) {
        this.categoryPatient._id = data.data._id;
        this.selectedAllergy = null;
        this.moreInfoAllergy = "";
      }
      this.$emit("allergieSaved", data.data, this.category);
      const el = this;
      setTimeout(function() {
        el.saveBtn = {
          color: "nblue",
          icon: "plus"
        };
      }, 300);

      this.$forceUpdate();
    }
  }
};
</script>
<style lang="scss">
.rmv-alrg-btn {
  display: none;
}
.tr-alergy:hover {
  .rmv-alrg-btn {
    position: absolute;
    top: calc(50% - 7.6px);
    right: 0px;
    display: inline-block;
  }
}
</style>
